body {
    background-color: #f7f7f7;
  }
  
  .App {
    text-align: center;
    padding: 20px;
  }
  
  main {
    margin-top: 40px;
  }
  
  .home {
    max-width: 600px;
    margin: 0 auto;
  }